<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card v-if="elementData.service_type">
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información Principal</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.name')"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="elementData.name"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="generic_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.generic_name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.generic_name')"
                  rules="required"
                >
                  <b-form-input
                    id="generic_name"
                    v-model="elementData.cups_name"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="medicine_group"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.medicine_group') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.medicine_group')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.medicine_group_id"
                    :options="medicineGroupList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="laboratory"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.laboratory') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.laboratory')"
                  rules="required"
                >
                  <b-form-input
                    id="laboratory"
                    v-model="elementData.laboratory"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="healt_registry"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.healt_registry') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.healt_registry')"
                  rules="required"
                >
                  <b-form-input
                    id="healt_registry"
                    v-model="elementData.healt_registry"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="cum_file"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.cum_file') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.cum_file')"
                  rules="required"
                >
                  <b-form-input
                    id="cum_file"
                    v-model="elementData.cups"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="cum_quantity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.cum_quantity') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.cum_quantity')"
                >
                  <b-form-input
                    id="cum_quantity"
                    v-model="elementData.cum_quantity"
                    maxlength="10"
                    pattern="^[0-9]*$"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="medicine_description"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.medicine_description') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.medicine_description')"
                  rules="required"
                >
                  <b-form-input
                    id="medicine_description"
                    v-model="elementData.description"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="administration_route"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.administration_route') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.administration_route')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.administration_route_id"
                    :options="administrationRouteList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="active_principle"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.active_principle') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.active_principle')"
                  rules="required"
                >
                  <b-form-input
                    id="active_principle"
                    v-model="elementData.active_principle"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="units_of_measure_id"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.units_of_measure') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.units_of_measure')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.units_of_measure_id"
                    :options="unitsOfMeasureList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="quantity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.quantity') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.quantity')"
                  rules="required"
                >
                  <b-form-input
                    id="quantity"
                    v-model="elementData.quantity"
                    maxlength="10"
                    pattern="^[0-9]*$"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="reference_unit"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.reference_unit') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.reference_unit')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.reference_unit_id"
                    :options="referenceUnitList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="dosage_form"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.dosage_form') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.dosage_form')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.dosage_form_id"
                    :options="dosageFormList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="regulated"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.regulated') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.regulated')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.regulated_id"
                    :options="booleanList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="minimum_delivery_unit"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.minimum_delivery_unit') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.minimum_delivery_unit')"
                  rules="required"
                >
                  <b-form-select
                    v-model="elementData.minimum_delivery_unit_id"
                    :options="deliveryUnitList"
                    value-field="id"
                    text-field="value"
                    disabled-field="notEnabled"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="new_stock_quantity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.new_stock_quantity') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.new_stock_quantity')"
                  rules="required"
                >
                  <b-form-input
                    id="new_stock_quantity"
                    v-model="new_stock_quantity"
                    maxlength="10"
                    pattern="^-?[0-9]*$"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="stock_quantity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.stock_quantity') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.stock_quantity')"
                  rules="required"
                >
                  <b-form-input
                    id="stock_quantity"
                    v-model="stock_quantity"
                    maxlength="10"
                    pattern="^[0-9]*$"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    serviceTypeList: {
      type: Array,
      required: true,
    },

    deliveryUnitList: {
      type: Array,
      required: true,
    },
    dosageFormList: {
      type: Array,
      required: true,
    },
    referenceUnitList: {
      type: Array,
      required: true,
    },
    medicineGroupList: {
      type: Array,
      required: true,
    },
    administrationRouteList: {
      type: Array,
      required: true,
    },
    unitsOfMeasureList: {
      type: Array,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      logoUpdated: false,
      logo: null,
      new_stock_quantity: 0,
      stock_quantity: 0,
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
    new_stock_quantity(newValue) {
      this.stock_quantity = parseInt(newValue) + parseInt(this.elementData.stock_quantity)
    },
    elementData(newValue) {
      this.stock_quantity = this.elementData.stock_quantity
    },
  },
  mounted() {

  },
  methods: {
    router() {
      return router
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.elementData.stock_quantity = this.stock_quantity
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style>

</style>
